<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Markisen / Gelenkarmmarkisen / Family Classic <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Family Classic - Der Klassiker
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full md:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full md:col-span-1">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Vielseitig und praktisch für Terrassen und Freisitze
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Ausgestattet mit typischem Volant schafft die Gelenkarmmarkise
            Family Classic sommerliche Atmosphäre auf Terrassen und Balkonen.
            Erhältlich in Formaten bis 700 cm (als Einzelfeld) und bis 1.950 cm
            als Koppelanlage ist diese Markise auch für große Flächen bestens
            geeignet. Benutzerfreundlich und in bewährter Lewens-Qualität kann
            die Family Classic mit vielen interessanten Optionen ergänzt werden.
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Typen">
            <div class="text-lg">
              <span class="font-bold"
                >Erhältlich in verschiedene Produktvarianten</span
              >
              <br />
              <ul class="flex flex-col sm:flex-row w-2/3">
                <li class="px-2">
                  <img
                    src="../assets/img/lewens/familyclassic/lew-fam-classic-o-detail-kappe-orange-xl.webp"
                    alt="gelenkmarkise family classic typ offen"
                  />Family Classic Typ Offen
                </li>
                <li class="px-2">
                  <img
                    src="../assets/img/lewens/familyclassic/lew-fam-classic-rd-detail-kappe-orange-xl.webp"
                    alt="gelenkmarkise family classic typ regendach"
                  />Family Classic Typ Regendach
                </li>
                <li class="px-2">
                  <img
                    src="../assets/img/lewens/familyclassic/lew-fam-classic-lmatik-detail-orange-xl-3.webp"
                    alt="gelenkmarkise family classic typ huelse"
                  />Family Classic Typ Hülse
                </li>
              </ul>
            </div>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 700 cm - 1-teilig (Einzelfeld)<br />
              max. 1.200cm - 2-teilig (mit durchgendem Tuch) <br />
              max. 1.950 cm - 2-/3-teilig (mit Schlitzabdeckung) <br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 400 cm <br />
              <span class="font-bold">Antrieb:</span> <br />
              Motorantrieb <br />
              Kurbelantrieb (Option)<br />
              <span class="font-bold">Volant:</span> <br />
              mit Volant <br />
              <span class="font-bold">Markisentücher:</span> <br />
              Marken-Acryl <br />
              Marken-Polyester (Option)
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div>
                    <img
                      src="../assets/madeingermany.webp"
                      alt="made in germany siegel"
                    />
                  </div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div><img src="../assets/ce-norm.webp" alt="ce-norm" /></div>
                  <div class="w-full pl-1">
                    Qualität und Technik nach CE-Norm
                  </div>
                </div>
                <div class="flex flex-row">
                  <div>
                    <img src="../assets/tuev.webp" alt="tuev geprueft" />
                  </div>
                  <div class="w-full pl-1">TÜV-geprüfte Sicherheit</div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/familyclassic/lew-fam-classic-ganz-haus1-uli.webp"),
          alt: "lewens family classic hausansicht",
        },
        {
          image: require("@/assets/img/lewens/familyclassic/lew-fam-classic-lmatik-ganz-haus2-uli.webp"),
          alt: "lewens family classic dachansicht",
        },
        {
          image: require("@/assets/img/lewens/familyclassic/lew-fam-classic-rd-detail-kappe-orange-xl.webp"),
          alt: "gelenkmarkise family classic rd detail",
        },
        {
          image: require("@/assets/img/lewens/familyclassic/lew-fam-classic-h-detail-kappe-orange-xl.webp"),
          alt: "family classic h detail kappe",
        },
        {
          image: require("@/assets/img/lewens/familyclassic/lew-fam-classic-lmatik-detail-orange-xl-3.webp"),
        },
        {
          image: require("@/assets/img/lewens/familyclassic/lew-fam-classic-lmatik-ganz-orange-xl-2.webp"),
          alt: "family classic haken",
        },
        {
          image: require("@/assets/img/lewens/familyclassic/lew-fam-classic-vv-ganz-orange-xl-2.webp"),
          alt: "family classic weite ausfahrrichtung",
        },
        {
          image: require("@/assets/img/lewens/familyclassic/lew-family-detail-armmittelgelenk2-xl-2.webp"),
          alt: "family classic orange",
        },
        {
          image: require("@/assets/img/lewens/familyclassic/modelle-web-rd-2.webp"),
          alt: "family classic ausfahrrichtung orange",
        },
      ],
    };
  },
};
</script>
